import React from 'react';
import { Button } from 'wix-ui-tpa/Button';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { Dialog as DialogComponent } from 'wix-ui-tpa/Dialog';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { DialogViewModel } from '../../ViewModel/dialogViewModel/dialogViewModel';
import { DataHooks } from './constants';
import { classes } from './Dialog.st.css';

export type DialogProps = {
  viewModel: DialogViewModel;
};

const Dialog: React.FC<DialogProps> = ({ viewModel }) => {
  const { onDialogClose, onDialogConfirm } = useCalendarActions();

  return (
    <DialogComponent
      data-hook={DataHooks.Root}
      isOpen={viewModel.isOpen}
      onClose={() => onDialogClose()}
    >
      <div className={classes.textWrapper}>
        <Text data-hook={DataHooks.Title} typography={TYPOGRAPHY.largeTitle}>
          {viewModel.titleText}
        </Text>
        <div className={classes.content}>
          <Text data-hook={DataHooks.Content} typography={TYPOGRAPHY.listText}>
            {viewModel.contentText}
          </Text>
        </div>
        <Button data-hook={DataHooks.Button} onClick={() => onDialogConfirm()}>
          {viewModel.confirmButtonText}
        </Button>
      </div>
    </DialogComponent>
  );
};

export default Dialog;
