export enum FiltersDataHooks {
  FILTERS_WRAPPER = 'filter-wrapper',
  FILTER_DIVIDER = 'header-divider',
  FILTER_CTA = 'filter-cta',
  ALL_OPTIONS = 'all-options',
  OPTION = 'option',
  SINGLE_ITEM_SELECTOR = 'single-item-selector',
  NOTE_DIVIDER = 'note-divider',
  NOTE = 'note',
}
