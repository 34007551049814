import React from 'react';
import {
  TimePickerStatus,
  TimePickerViewModel,
} from '../../ViewModel/timePickerViewModel/timePickerViewModel';
import { TimePickerDataHooks } from './TimePickerDataHooks';
import { Text } from 'wix-ui-tpa/Text';
import { classes, st } from './TimePicker.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { useEnvironment } from 'yoshi-flow-editor';
import { Spinner } from 'wix-ui-tpa/Spinner';
import { SPINNER_TYPES } from 'wix-ui-tpa/dist/src/components/Spinner/types';
import NoAvailableSlots from './NoAvailableSlots/NoAvailableSlots';
import TimeSelection from './TimeSelection/TimeSelection';
import TimezoneSelection from './TimezoneSelection/TimezoneSelection';
import TimePickerNotification from './TimePickerNotification/TimePickerNotification';

export type TimePickerProps = {
  viewModel: TimePickerViewModel;
};

const TimePicker: React.FC<TimePickerProps> = ({ viewModel }) => {
  const {
    formattedSelectedDate,
    noAvailableSlotsViewModel,
    timeSelectionViewModel,
    timezoneSelectionViewModel,
    notificationViewModel,
  } = viewModel;
  const { isMobile, isRTL } = useEnvironment();

  const getTimePickerLoader = () => (
    <div
      data-hook={TimePickerDataHooks.LOADER}
      className={st(classes.loader, {
        isMobile,
      })}
    >
      <Spinner diameter={24} isCentered={true} type={SPINNER_TYPES.slim} />
    </div>
  );

  const getTimePickerContentByStatus = () => {
    switch (viewModel.status) {
      case TimePickerStatus.LOADING:
        return getTimePickerLoader();
      case TimePickerStatus.NO_AVAILABLE_SLOTS_FOR_SELECTED_DATE:
        return <NoAvailableSlots viewModel={noAvailableSlotsViewModel} />;
      case TimePickerStatus.LOADED:
        return (
          <div>
            <TimePickerNotification viewModel={notificationViewModel} />
            <TimeSelection viewModel={timeSelectionViewModel} />
          </div>
        );
      default:
        return getTimePickerLoader();
    }
  };

  return (
    <>
      <div
        id="alerts"
        role="alert"
        className={classes.srOnly}
        data-hook={TimePickerDataHooks.SR_ONLY_ANNOUNCEMENT}
      >
        {viewModel.accessibility.onTimePickerLoadedAnnouncement}
      </div>
      <div
        data-hook={TimePickerDataHooks.TIME_PICKER}
        className={st(classes.root, { isMobile, isRTL })}
      >
        <SelectedDate
          selectedDate={formattedSelectedDate!}
          srOnlySelectedDate={viewModel.accessibility.selectedDate}
        />
        {timezoneSelectionViewModel ? (
          <TimezoneSelection viewModel={timezoneSelectionViewModel} />
        ) : null}
        {getTimePickerContentByStatus()}
      </div>
    </>
  );
};

export default TimePicker;

type SelectedDateProps = {
  selectedDate: string;
  srOnlySelectedDate: string;
};

const SelectedDate: React.FC<SelectedDateProps> = ({
  selectedDate,
  srOnlySelectedDate,
}): JSX.Element => {
  return (
    <>
      <div
        className={classes.srOnly}
        data-hook={TimePickerDataHooks.SR_ONLY_SELECTED_DATE}
      >
        {srOnlySelectedDate}
      </div>
      <Text
        data-hook={TimePickerDataHooks.SELECTED_DATE}
        className={st(classes.formattedSelectedDate)}
        tagName={AccessibilityHtmlTags.Paragraph}
        aria-hidden="true"
      >
        {selectedDate}
      </Text>
    </>
  );
};
